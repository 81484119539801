<template>
    <div class="wrapper_home mg_top">
        <div class="container ner_responesize">
            <div class="wrapper_game_home">
                <h2>Casino Game</h2>
                <div class="slider_casino">
                    <carousel :wrap-around="false" :breakpoints="breakpoints">
                        <slide :key="index" v-for="(item, index) in listBanner">
                            <router-link to="/danh-sach-ban">
                                <img :src="item.img" alt="">
                            </router-link>
                        </slide>
                        <template #addons>
                            <!-- <Navigation /> -->
                            <Pagination />
                        </template>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
    name: 'Home_tool',
    setup() {
		return {
			breakpoints: {
                0: {
					itemsToShow: 1.5,
					snapAlign: "center"
				},
				431: {
					itemsToShow: 2,
					snapAlign: "center"
				},
				768: {
					itemsToShow: 3,
					snapAlign: "center"
				},
				1024: {
					itemsToShow: 4,
					snapAlign: "center"
				},
                1280: {
					itemsToShow: 5,
					snapAlign: "center"
				},
			}
		};
	},
    data () {
        return {
            listBanner: []
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        // Navigation,
    },
    mounted () {
        
    },
    created () {
        this.GetListBanner()
    },
    computed: {
        
    },
    methods: {
        GetListBanner () {
            this.listBanner = [
                { img: require('@/assets/images/1.png')},
                { img: require('@/assets/images/2.png')},
                { img: require('@/assets/images/3.png')},
                { img: require('@/assets/images/4.png')},
                { img: require('@/assets/images/5.png')},
                { img: require('@/assets/images/6.png')},
                { img: require('@/assets/images/7.png')},
                { img: require('@/assets/images/8.png')},
                { img: require('@/assets/images/9.png')},
            ]
        },
    }
}
</script>