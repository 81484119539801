<template>
    <div class="wrapper_home mg_top">
        <div class="container ner_responesize">
            <div class="wp_result_tool">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 right_chart">
                        <div class="wp_chart">
                            <h3 class="title_nametable">Bàn {{ urlId }}</h3>
                            <div class="note_chart">
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_number">
                                        <div class="tonggiaodichrow">
                                            <h4>PLAYER </h4>
                                            <h5>Tổng: {{ formatNumber(xanhTong) }}</h5>
                                            <!-- <h5>Tổng: {{ formatNumber(randomGDX) }}</h5> -->
                                            <div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Số tiền GD</th>
                                                            <th>Thời gian</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="giaodichxanh in loadMoreListXanh" :key="giaodichxanh.id" class="up_down">
                                                            <td id="updown" :class="{ 'up': giaodichxanh.changes > 0, 'down': giaodichxanh.changes < 0 }">{{ formatNumber(giaodichxanh.value) }}</td>
                                                            <td>{{ formattedTime(giaodichxanh.timestamp) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p class="loadsmore_data loadsmore_datax" v-if="length_xanh < giaodichxanh.length"><span @click="loadMoreXanh">Xem thêm</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_number">
                                        <div class="tonggiaodichrow">
                                            <div class="tonggiaodichrow">
                                                <h4>TIE</h4>
                                                <h5>Tổng: {{ formatNumber(hoatong) }}</h5>
                                                <!-- <h5>Tổng: {{ formatNumber(randomGDH) }}</h5> -->
                                                <div>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Số tiền GD</th>
                                                                <th>Thời gian</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="giaodichhoa in loadMoreListHoa" :key="giaodichhoa.id" class="up_down">
                                                                <td id="updown" :class="{ 'up': giaodichhoa.changes > 0, 'down': giaodichhoa.changes < 0 }">{{ formatNumber(giaodichhoa.value) }}</td>
                                                                <td>{{ formattedTime(giaodichhoa.timestamp) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <p class="loadsmore_data loadsmore_datah" v-if="length_hoa < giaodichhoa.length"><span @click="loadMoreHoa">Xem thêm</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_number">
                                        <div class="tonggiaodichrow">
                                            <div class="tonggiaodichrow">
                                                <h4>BANKER</h4>
                                                <h5>Tổng: {{ formatNumber(dotong) }}</h5>
                                                <!-- <h5>Tổng: {{ formatNumber(randomGDD) }}</h5> -->
                                                <div>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Số tiền GD</th>
                                                                <th>Thời gian</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="giaodichdo in loadMoreListDo" :key="giaodichdo.id" class="up_down">
                                                                <td id="updown" :class="{ 'up': giaodichdo.changes > 0, 'down': giaodichdo.changes < 0 }">{{ formatNumber(giaodichdo.value) }}</td>
                                                                <td>{{ formattedTime(giaodichdo.timestamp) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <p class="loadsmore_data loadsmore_datad" v-if="length_do < giaodichdo.length"><span @click="loadMoreDo">Xem thêm</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_note">
                                        <div class="item_note">
                                            <h3>{{percentages.columnP}}%</h3>
                                            <p class="pxd">P</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_note">
                                        <div class="item_note">
                                            <h3>{{percentages.columnT}}%</h3>
                                            <p class="pxl">T</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_note">
                                        <div class="item_note">
                                            <h3>{{percentages.columnB}}%</h3>
                                            <p class="pd">B</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 left_info">
                        <div class="result_frm">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-6 item_dudoan">
                                    <div class="wp_dudoan_root">
                                        <p class="tt_dd">Dự đoán kết quả sau <span :class="cl_red">{{ time }}s</span></p>
                                        <div class="wp_dudoan">
                                            <div class="circle">
                                                <div class="circle-inner" :style="{ 'background': `conic-gradient(#06EE23 0% ${urlpercent}%, rgba(0,0,0,0) 50% 100%)` }"></div>
                                                <div class="circle-overlay"></div>
                                                <span class="percentage-text">{{ urlpercent }}%</span>
                                            </div>
                                        </div>
                                        <div class="wp_dudoan">
                                            <div class="loading-circle" v-if="loading == 0">
                                                <div class="inner-circle inner-circlep" v-if="percentages.columnP > percentages.columnB">
                                                    <span class="icon">P</span>
                                                </div>
                                                <div class="inner-circle inner-circleb" v-else>
                                                    <span class="icon">B</span>
                                                </div>
                                            </div>
                                            <div class="loading-result" v-else>
                                                <p>Ngưng cược</p>
                                                <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 item_dudoan">
                                    <div class="root_ratio_game">
                                        <div class="grid-container">
                                            <div class="grid" v-if="result.length <= 0">
                                                <div v-for="i in 72" :key="i" class="grid-cell"></div>
                                            </div>
                                            <div class="grid" v-else>
                                                <div v-for="cell in result" :key="cell" class="grid-cell">
                                                    <span :class="'spancell span' + (cell && getFirstCharacter(cell))">{{ cell && getFirstCharacter(cell) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Chart_tool from '@/components/Chart.vue'
export default {
    name: 'Result_tool',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            data: [],
            result: [],
            urlpercent: this.$route.params.percent,
            urlId: this.$route.params.id,
            percentages: {
                columnT: 0,
                columnP: 0,
                columnB: 0,
            },
            giaodichxanh: [
                { id: 1, value: 1400000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 2, value: 4200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 3, value: 6800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 4, value: 8500000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 5, value: 12600000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 6, value: 32000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 7, value: 37800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 8, value: 45900000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 9, value: 52700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 10, value: 61200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 11, value: 65400000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 12, value: 69800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 13, value: 73700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 14, value: 86400000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 15, value: 91200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 16, value: 132700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 17, value: 168900000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 18, value: 256300000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 19, value: 386900000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 20, value: 500000000, change: 0, changes: 0, timestamp: this.randomTimestamp() }
            ],
            giaodichhoa: [
                { id: 1, value: 1100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 2, value: 2600000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 3, value: 3500000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 4, value: 5700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 5, value: 8100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 6, value: 11200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 7, value: 14600000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 8, value: 26700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 9, value: 29800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 10, value: 42100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 11, value: 57800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 12, value: 61000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 13, value: 68100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 14, value: 75400000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 15, value: 81300000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 16, value: 94700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 17, value: 111000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 18, value: 216000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 19, value: 257800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 20, value: 289900000, change: 0, changes: 0, timestamp: this.randomTimestamp() }
            ],
            giaodichdo: [
                { id: 1, value: 1000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 2, value: 3600000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 3, value: 8700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 4, value: 12500000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 5, value: 19700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 6, value: 21800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 7, value: 25100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 8, value: 46300000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 9, value: 59800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 10, value: 71200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 11, value: 86900000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 12, value: 91100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 13, value: 93200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 14, value: 126900000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 15, value: 151100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 16, value: 189200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 17, value: 210000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 18, value: 258700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 19, value: 325600000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 20, value: 500000000, change: 0, changes: 0, timestamp: this.randomTimestamp() }
            ],
            tonggiaodichxanh: [10256000000, 13265000000, 16259000000, 21210000000, 25695000000, 32025000000, 36149000000, 45928000000, 48698000000],
            randomGDX: 16259000000,
            tonggiaodichhoa: [565000000, 743000000, 879000000, 940000000, 1256000000, 2100000000, 2658000000, 3630000000, 4179000000, 4836000000],
            randomGDH: 1256000000,
            tonggiaodichdo: [11259000000, 14746000000, 19231000000, 22148000000, 26164000000, 29365000000, 35652000000, 41191000000, 49895000000 ],
            randomGDD: 14746000000,

            xanhTong: 16126000000,
            hoatong: 512000000,
            dotong: 17236000000,
            arrNumber: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20 ],
            length_xanh: 10,
            length_hoa: 10,
            length_do: 10,
            strResult: [],
            loading: 0,
            time: 50,
            interval: null,
            cl_red: ''
        }
    },
    components: {
        // Chart_tool,
    },
    computed: {
        loadMoreListXanh() {
            return this.giaodichxanh.slice(0, this.length_xanh);
        },
        loadMoreListHoa() {
            return this.giaodichhoa.slice(0, this.length_hoa);
        },
        loadMoreListDo() {
            return this.giaodichdo.slice(0, this.length_do);
        },
    },
    mounted () {
        this.generatePercentages();
        this.updateDataArray()
    },
    created () {
        this.getDataString()
        setInterval(() => {
            this.result = []
            this.getDataString()
            // setTimeout(() => {
            //     this.loading = 1
            // }, 1000);
            setTimeout(() => {
                this.cl_red = ''
                this.loading = 0
                this.generatePercentages()
                this.startCountdown()
            }, 300);
        }, 50000);
       this.startCountdown() 
    },
    methods: {
        getDataString () {
            this.axios.get(this.api_result).then((response) => {
                this.strResult = response.data.data.filter(d => d.roomId === this.urlId)
                this.data = this.strResult[0].statistics

                if(window.innerWidth > 601){
                    const rows = 12
                    const cols = 6
                    for (let col = 0; col < cols; col++) {
                        for (let row = 0; row < rows; row++) {
                            this.result.push(this.data[row * cols + col])
                        }
                    }
                }else{
                    const rows = 9
                    const cols = 8
                    for (let col = 0; col < cols; col++) {
                        for (let row = 0; row < rows; row++) {
                            this.result.push(this.data[row * cols + col])
                        }
                    }
                }
            })
            // let str = 'ptpbbpttbbbtttpbtptbbtptpbpbt'
            // this.data = Array.from(str)

            // let randomIndex = Math.floor(Math.random() * this.data.length);
            // this.dataRandom = this.data[randomIndex];
            // if(window.innerWidth > 601){
            //     const rows = 12
            //     const cols = 6
            //     for (let col = 0; col < cols; col++) {
            //         for (let row = 0; row < rows; row++) {
            //             this.result.push(this.data[row * cols + col])
            //         }
            //     }
            // }else{
            //     const rows = 9
            //     const cols = 7
            //     for (let col = 0; col < cols; col++) {
            //         for (let row = 0; row < rows; row++) {
            //             this.result.push(this.data[row * cols + col])
            //         }
            //     }
            // }
        },
        getFirstCharacter(str) {
            return str.charAt(0);
        },
        reverseArray(arr) {
            arr.reverse();
        },
        generatePercentages() {
            let columnT = Math.floor(Math.random() * 30);
            let columnP = Math.floor(Math.random() * (100 - columnT));
            // let c = 100 - a - b;
            const values = [columnT, columnP].sort((x, y) => x - y);
            this.percentages = {
                columnT: values[0],
                columnP: values[1],
                columnB: 100 - values[0] - values[1],
            };            
        },
        formatNumber(value) {
            // vi-VN
            return value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
        },
        randomTimestamp() {
            const now = Date.now();
            const fiveHoursAgo = now - 60 * 1000;
            // const fiveHoursAgo = now - 2 * 60 * 60 * 1000;
            return Math.floor(Math.random() * (now - fiveHoursAgo + 1) + fiveHoursAgo);
        },
        updateDataArray() {
            setInterval(() => {
                // Giao dịch xanh
                const randomIndex = Math.floor(Math.random() * this.tonggiaodichxanh.length);
                this.randomGDX = this.tonggiaodichxanh[randomIndex];

                const randomArray = Math.floor(Math.random() * this.arrNumber.length);
                const valRandom = this.arrNumber[randomArray];
                this.xanhTong = this.xanhTong + valRandom * 1000000;
                
                this.giaodichxanh = this.giaodichxanh.map(giaodichxanh => {
                    const change = Math.random() * 0.0009;
                    const changes = Math.random() * 0.0018 - 0.0009;
                    // const change = (Math.random() - 0.5) * 2;
                    // value: Math.round((giaodichxanh.value + giaodichxanh.value * change / 100) * 100) / 100,
                    return {
                        ...giaodichxanh,
                        value: giaodichxanh.value,
                        change: change,
                        changes: changes,
                        timestamp: this.randomTimestamp(),
                    }
                }).sort((a, b) => a.timestamp - b.timestamp);
            }, 3000)
            setInterval(() => {
                // gGiao dịch hòa
                const randomIndex = Math.floor(Math.random() * this.tonggiaodichhoa.length);
                this.randomGDH = this.tonggiaodichhoa[randomIndex];

                const randomArray = Math.floor(Math.random() * this.arrNumber.length);
                const valRandom = this.arrNumber[randomArray];
                this.hoatong = this.hoatong + valRandom * 1000000;

                this.giaodichhoa = this.giaodichhoa.map(giaodichhoa => {
                    const change = Math.random() * 0.0009;
                    const changes = Math.random() * 0.0018 - 0.0009;
                    return {
                        ...giaodichhoa,
                        value: giaodichhoa.value,
                        change: change,
                        changes: changes,
                        timestamp: this.randomTimestamp()
                    }
                }).sort((a, b) => a.timestamp - b.timestamp);
            }, 4000)
            setInterval(() => {
                // gGiao dịch đỏ
                const randomIndex = Math.floor(Math.random() * this.tonggiaodichdo.length);
                this.randomGDD = this.tonggiaodichdo[randomIndex];

                const randomArray = Math.floor(Math.random() * this.arrNumber.length);
                const valRandom = this.arrNumber[randomArray];
                this.dotong = this.dotong + valRandom * 1000000;

                this.giaodichdo = this.giaodichdo.map(giaodichdo => {
                    const change = Math.random() * 0.0009;
                    const changes = Math.random() * 0.0018 - 0.0009;
                    return {
                        ...giaodichdo,
                        value: giaodichdo.value,
                        change: change,
                        changes: changes,
                        timestamp: this.randomTimestamp()
                    }
                }).sort((a, b) => a.timestamp - b.timestamp);
            }, 3500)
        },
        loadMoreXanh() {
            if (this.length_xanh > this.giaodichxanh.length) return;
            this.length_xanh = this.length_xanh + 10;
        },
        loadMoreHoa() {
            if (this.length_hoa > this.giaodichhoa.length) return;
            this.length_hoa = this.length_hoa + 10;
        },
        loadMoreDo() {
            if (this.length_do > this.giaodichdo.length) return;
            this.length_do = this.length_do + 10;
        },
        formattedTime(d) {
            const date = new Date(d);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            // const year = date.getFullYear();
            return `${hours}:${minutes}:${seconds} ${day}/${month}`;
        },
        startCountdown() {
            // Nếu bộ đếm đang chạy, dừng lại trước khi bắt đầu lại
            if (this.interval) {
                clearInterval(this.interval);
            }
            // Thiết lập lại thời gian
            this.time = 50;
            // Khởi tạo bộ đếm
            this.interval = setInterval(() => {
                if(this.time <= 11){
                    this.loading = 1
                    this.cl_red = 'cl_red'
                }else{
                    this.cl_red = ''
                }
                if (this.time > 0) {
                    this.time--; // Giảm thời gian mỗi giây
                } else {
                    // this.loading = 1
                    // clearInterval(this.interval); // Dừng bộ đếm khi thời gian kết thúc
                }
            }, 1000);
        },
    }
}
</script>
<style scoped>
</style>